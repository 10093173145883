
import type { PropType } from 'vue'
import {
  defineComponent, ref, toRefs
} from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import type { ContactBlocked } from '@/definitions/contacts/blocked/types'

export default defineComponent({
  name: 'ContactsBlockedFormModal',
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  props: {
    contact: {
      type: Object as PropType<ContactBlocked>,
    },
  },
  setup(props) {
    const { contact } = toRefs(props)

    const phoneNumber = ref((contact.value) ? contact.value.phone : '+1')

    return {
      phoneNumber,
    }
  },
})
